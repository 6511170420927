import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { PropsFromRedux } from '.';
import styles from './OurOffices.module.scss';
import OfficesData from '../../components/OfficesData';

interface Props extends PropsFromRedux {}

const OurOffices: React.FC<Props> = (props) => (
  <div className="w-full">
    <div className={styles.offices_search_wrapper}>
      <div className="container flex justify-center w-full align-center ">
        <div className="grid grid-cols-1 gap-10 pt-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 ">
          <div>
            <h3 className="mb-3 font-bold">Nigeria contact:</h3>
            <div className="flex gap-2 mt-2 mb-1 align-center">
              <span>
                <FaEnvelope className="mt-1" />
              </span>
              <span>
                <a href="mailto:info@giglogistics.com">info@giglogistics.com</a>
              </span>
            </div>
            <div className="flex gap-2 align-center">
              <span>
                <FaPhoneAlt className="mt-1" />
              </span>
              <span>
                <a href="tel:08139851120">+234 813 985 1120  </a>
              </span>
            </div>
          </div>
          <div>
            <h3 className="font-bold">Ghana contact:</h3>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaEnvelope className="mt-1" />
              </span>
              <span>
                <a href="mailto:giglghana@giglogistics.com">
                  giglghana@giglogistics.com
                </a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaPhoneAlt className="mt-1" />
              </span>
              <span>
                <a href="tel:+233 592 009 946">+233 592 009 946</a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaWhatsapp className="mt-1" />
              </span>
              <span>
                <a href="tel:+233 575 341 111">+233 575 341 111</a>
              </span>
            </div>
          </div>
          <div>
            <h3 className="font-bold">UK contact:</h3>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaEnvelope className="mt-1" />
              </span>
              <span>
                <a href="mailto:gigluk@giglogistics.com">
                  gigluk@giglogistics.com
                </a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaPhoneAlt className="mt-1" />
              </span>
              <span>
                <a href="tel:+447487622536">+44 7487 622 536
                </a>


              </span>
            </div>
          </div>
          <div>
            <h3 className="font-bold">Houston contact:</h3>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaEnvelope className="mt-1" />
              </span>
              <span>
                <a href="mailto:giglusa@giglogistics.com">
                  giglusa@giglogistics.com
                </a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span>
                <FaPhoneAlt className="mt-1" />
              </span>
              <span>
                <a href="tel:+2817411784">+(281) 741-1784</a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span className="mt-1">
                <FaWhatsapp />
              </span>
              <span>
                <a href="tel:+1 (346) 774 4344">+1 (346) 774 4344</a>
              </span>
            </div>
          </div>
          <div>
            <h3 className="font-bold">China contact:</h3>
            <div className="flex gap-2 mt-2 align-center">
              <span className="mt-1">
                <FaEnvelope />
              </span>
              <span>
                <a href="mailto:china@giglogistics.com">
                  china@giglogistics.com
                </a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span className="mt-1">
                <FaWhatsapp />
              </span>
              <span>
                <a href="tel:+234 913 934 6234">+234 913 934 6234</a>
              </span>
            </div>
          </div>

          <div>
          <h3 className="font-bold">Toronto Canada contact:</h3>
          
            <div className="flex gap-2 mt-2 align-center">
              <span className="mt-1">
                <FaEnvelope />
              </span>
              <span>
                <a href="mailto:giglcanada@giglogistics.com">
                giglcanada@giglogistics.com
                </a>
              </span>
            </div>
            <div className="flex gap-2 mt-2 align-center">
              <span className="mt-1">
                <FaPhoneAlt />
              </span>
              <span>
                <a href="tel:+12892122225 ">+1 289 212 2225 </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container flex flex-col items-center justify-center py-20 mx-auto">
        <h1 className="mb-5 text-4xl font-bold">Our Offices</h1>
      </div>
    </div>
    <div className="container w-full mx-auto">
      <OfficesData />
    </div>
  </div>
);

export default OurOffices;
