import { uid } from 'uid';

export interface FAQData {
  id: string;
  category: string;
  question: string;
  answer: string;
}

export const faqData: FAQData[] = [
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'What is Global Shipping?',
    answer:
      'Global shipping is a GIGL solution that allows you ship in and export items from any of your favorite stores abroad, anywhere in the world and ship to your doorstep in Nigeria.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'How do I ship from the USA/UK/CHINA to Nigeria?',
    answer:
      'Easy! Simply download the <a href="https://play.google.com/store/apps/details?id=ng.giglogistics.giglgo" class="underline">GIGGo App</a> here to get started. Then click “Send to Nigeria” on the GIGGo App, select country and follow the prompts. The GIGL global shipment process is seamless and efficient.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'Who can ship globally?',
    answer:
      'Anyone with a fully updated user profile on the GIGGo App can ship overseas.  E-commerce merchants must use their regular account to ship.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'How do I make payment?',
    answer:
      'After we receive your item physically at either our UK, USA, or CHINA office, you will get a payment link through which you can make payments. The link offers you the option of making secured payments through your funded wallet on the GIGGo App, a third-party payment platform (Paystack/Flutterwave), or your Bank Card.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question:
      'Will my parcel be delivered to my final destination anywhere in Nigeria?',
    answer:
      '<p class="mb-2">Yes, we will deliver anywhere in Nigeria so long as your preferred address is listed under the GIGL home delivery locations.</p> <p>We are constantly striving to serve you better and are rapidly expanding to cover all locations within Nigeria.</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'Will GIGL require you to pay into any personal account?',
    answer:
      '<p>No, GIGL will never ask you to pay into any numbered account. All payments are either deducted straight from your GIGGo wallet or through an authentic and secure payment link that offers you 3 payment options –</p> <ul class="list-disc pl-8"><li>GIGGo Wallet</li><li>Third party payment platform (Paystack or Flutterwave)</li><li>Bank Debit or Credit  Card</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'What are your import rates?',
    answer:
      '<a href="/overseas-shipping" class="underline">Click here to view prices</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question:
      'What is the expected time of arrival (ETA) for international shipments?',
    answer:
      '<p>US to Nigeria: 7-10 business days</p><p>UK to Nigeria: 3-5 business  days</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'Does GIGL ship all kinds of items?',
    answer:
      'Yes, excluding items prohibited by law enforcement agencies. The size of your item is no barrier as special arrangements are available for supersized items',
  },
  
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Global Shipping',
    question: 'What are your export rate?',
    answer: 'Visit our website for more on export rate per',
  },

  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'Who is an E-commerce Merchant in GIGL?',
    answer:
      '<p class="mb-2">A GIGL E-commerce Merchant is anyone signed up on the GIGL’s e-commerce Merchant platform, either on the GIGGo App or through the e-commerce merchant portal on the GIGL website. This platform is designed to give massively discounted rates and plenty other benefits to customers who deliver products frequently and in large quantity.</p> <p>Its flexibility and additional offerings make it an ideal choice for SMEs or Social/E-commerce businesses. If you sell online or on Instagram, Twitter or Facebook, this is great for you!</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'How can I become an e-commerce merchant?',
    answer:
      'By signing up on the GIGGo App as an e-commerce merchant or <a href="https://www.gigl-go.com/merchantSignUp" target="_blank" class="underline">through the website</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'What benefits do I get as an e-commerce merchant?',
    answer:
      '<p class="mb-2">The GIGL E-commerce Merchant platform features 2 plans called GIGL Basic and GIGL CLASS.</p><p> The GIGL Basic plan offers you:</p><ul class="mb-4 list-disc pl-8"><li>Shipping discount worth (2.5%) two percent</li><li>E-commerce business growth kit</li></ul><p>On the GIGL CLASS plan, you get</p><ul class="list-disc pl-8"><li>Fifteen percent (15%) discount on local shipping fees <li>Ten percent (10%) discount on global shipping fees</li><li>Free insurance cover on shipments capped at 100k</li><li>Priority Shipping</li><li>No handling charges on Cash on Delivery (COD) & Transfer on Delivery (TOD)</li><li>Free bulk pickup for interstate deliveries</li><li>E-commerce growth kit</li><li>A dedicated Account Officer</li><li>Media visibility on Digital Millionaire for business growth</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question:
      'How much do I need to sign up on the E-commerce Merchant Platform?',
    answer:
      'The Basic Plan is free. On the Class Plan, you will need to subscribe monthly at ₦3,999',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'Do I need to have a registered company/business?',
    answer: 'No',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question:
      'Can I shop and ship from international sites (e.g. Amazon) into Nigeria?',
    answer:
      'Yes, please. You can shop and ship directly through the stores listed on the GIGGo App or shop from other online stores and ship through the <a href="https://play.google.com/store/apps/details?id=ng.giglogistics.giglgo" class="underline" target="_blank">GIGGo App.</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'How do I pay for my deliveries?',
    answer:
      'By simply funding your wallet via the GIGGo App or the e-commerce merchant portal on the website. Over the counter payments are not allowed.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'How do I track my shipments?',
    answer:
      'You can track it by inserting your waybill number into the “track tab” on your GIGGo App, e-commerce merchant portal or website homepage.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'Can I process C.O.D transactions on the GIGGo App?',
    answer:
      'This function is not yet available at the moment. However, you can physically process this at any of our experience centers in the interim.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'When do I get paid for my C.O.D transactions?',
    answer:
      'You get paid in 7 working days after successful delivery has been confirmed',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - E-commerce Merchants',
    question: 'What Is the delivery timeline?',
    answer:
      '2-3 working days (under the right conditions, depending on the zone and region in the country). Same day deliveries are available in select cities- Lagos, Port- Harcourt and Abuja.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the GIGGo App?',
    answer:
      'The GIGGo App by GIG Logistics is an efficient on-demand app built to solve your logistics needs and be your delivery companion. It handles everything from automated deliveries and pickup requests to global shipping, transaction payments, and more. Use it on the go, at your convenience, from anywhere in the world on your own terms.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the timeline for pick up?',
    answer: 'Pickup is real-time but also based on availability of a rider.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What happens if the rider cancels my request?',
    answer:
      'Pick up will be assigned to another available rider. Shipping fee will also be refunded if the customer cancels the request.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Will a customer be charged after the 5 minutes wait time?',
    answer: 'Yes',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What type of vehicles are available for delivery?',
    answer:
      '<p>Depending on the size of the shipment, all of the following are available to be used for delivery.</p> <ul class="py-4 list-disc pl-8"><li>Motor Bike</li><li>Car</li><li>Mini Van</li><li>Truck</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What happens when a shipment gets damaged or missing?',
    answer:
      'This rarely happens but in the event that it does, GIG Logistics will make full refund to the customer based on the value declared of item(s) missing or damaged.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'From which locations can I request a pickup service?',
    answer:
      'You can use the GIGGo App from any city in the world to order pick up from Lagos, Abuja, Benin, Port Harcourt, Asaba, Awka.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Is the GIGGo App available 24/7?',
    answer:
      'Yes, it is. However, pickup requests will only be assigned to a rider between 6am-6pm. Any request after 6pm will be put on the priority queue and assigned the next day.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the mode of payment on the GIGGo App?',
    answer:
      'GIGGo is a strictly wallet-based system. No cash is used when transacting through the App',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is Express Drop-Off?',
    answer:
     "It is a feature on the GIGGo App that helps you fast-track shipment processing. Simply create a shipment on the GIGGo App, present the unique code generated by the app to any of our agents at your preferred GIGL Experience Centre, and drop off your shipment for prompt service. Use this feature to save time and skip queues at our Experience Centres!",
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I process payment for Express Drop-Off?',
    answer:
      'You can pay from your GIGGo wallet, use cash, or pay via POS at any of our experience centres.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I fund my wallet?',
    answer:
    `<p> Click the <strong> ‘Fund’ </strong> cion on the GIGGo App, enter amount, and select a payment option for instant value. </p>`,
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question:
      'How do I make payment with the GIGGo wallet for Express Drop-Off?',
    answer:
    `Click the <strong> ‘Menu’ option </strong>, then <strong>‘Process service centre pickup’ </strong>, to make payment`,
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'When am I to select car for pickup?',
    answer:
      'When the shipment is either too big for a bike delivery box or too small for a van.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question:
      'For small shipments going outside Lagos, what vehicle type should be selected?',
    answer:
      'The vehicle type will depend on the size of the item. Please select “Bike” for items that can fit the delivery box and a car for items too small for a Van but too large for a Bike. Trucks are available for larger items like home furniture or machinery, etc. For further guidance, please contact customer service on <strong>0813 985 1120</strong>.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Can I create multiple shipments with one waybill number?',
    answer:
      'Yes, you can—provided all items are being delivered to a single receiver at the same location.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Am I allowed to tip the rider?',
    answer:
      'No please. Our riders earn sufficiently to be able to serve you daily.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I report a complaint?',
    answer:
      'Please call our Contact Centre on 0813 985 1120 or send an email to info@giglogistics.com. Our care line is available from 7 am- 9pm daily.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics UK Terms and Conditions',
    question: 'Global Shipping ( from the UK to Nigeria ) - what you need to know',
    answer: `<p class="font-medium">By using the Send to Nigeria feature on the GIGGo app, you can now shop from your favourite stores in the UK and have your item(s) delivered to your doorstep in Nigeria without restrictions. The estimated time for delivery is usually between three to five (3 – 5) business days after the item’s departure from our delivery hub. Our rates from the UK to all cities in Nigeria are:</p>
      <ul class="pl-5 my-5">
      <li> • £4.5  per kg for regular Items</li>
      <li> • £27  A flat rate for items under 6kg.</li>
      <li> • £50 for Laptops, Palmtops, Tablets, Ipads (7-18 inches)</li>
      <li> • £30 each for Children’s Tablets and Smartphones</li>
      <li> • £25 flat rate for used gadgets under 6kg.</li>
      <li> • £5 per kg for used gadgets weighing 6kg and above</li>
      <li> • £30 for perfumes under 6kg, while items weighing 6kg and above will be charged at £6 per kg.</li>
      </ul>
      <p class="font-medium mb-5">Volumetric weight applies to charges in cases where it is the highest: e.g., a television and bulky baby items International Volumetric Weights are calculated using the formula below:</p>
      <p class="font-medium mb-5 pl-5 italic">(Length x Width x Height in centimeters) divided by 6000 = Volumetric Weight in kilograms</p><p class="font-medium mb-5">Please note that the actual shipping price is subject to the volumetric weight of the item(s) and an optional insurance fee of 7.5% on the total value of the item(s). If you’re shipping multiple items, you can also take advantage of our consolidated shipping service(GoStandard) while saving time and money. Handling fees apply for special packaging, such as liquid and fragile items.</p> <p class="font-medium mb-5">For further enquiries, we are happy to help. Please contact us at gigluk@giglogistics.com <br /><br />  It takes less than 5 minutes to complete a shipping request on the GIGGo App. You should try it! </p> 

      
      
<p class="font-medium mb-5">All you have to do is fill in the required information and provide a Government Recognized Photo Identification (International Passport or Driver's License) to complete the shipping process.
</p>

<p class="font-medium mb-5">Please fill in the required information and provide a Government Recognized Photo Identification.(International Passport or Driver's License) Follow the format below when you purchase an item online:</p>
<ul class="pl-5 my-5">
<p>Shopping online? Follow the format below to ship your items:
</p>
<br />
      <li> *YOUR NAME*</li>
      <li>GIG Logistics UK,<br />381-383 Lewisham High St, London SE13 6NZ, United Kingdom
</li>
      </ul>
    
    `,
  },
];
